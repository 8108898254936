import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { userAuthorization } from 'redux/authorizationSlice';

type Routetype = {
  auth: boolean;
  children: any;
};

const PrivateRoute = ({ auth, children }: Routetype) => {
  const { isServerError } = useSelector(userAuthorization);

  if (isServerError) {
    return <Navigate to={NAVIGATION_MENU_PATH.ERROR} />;
  }

  return auth ? (
    children
  ) : (
    <Navigate to={NAVIGATION_MENU_PATH.NOT_AUTHORIZED} />
  );
};

export default PrivateRoute;
