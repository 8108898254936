import { LoaderIcon } from 'assets/icons';
import Icon from 'components/Icon';

import './index.scss';

type LoaderProps = {
  wrapperClassName?: string;
  iconWidth?: number;
  iconHeight?: number;
  additionalText?: React.ReactNode;
};

const Loader = ({
  wrapperClassName,
  iconWidth,
  iconHeight,
  additionalText,
}: LoaderProps) => {
  return (
    <div
      className={`${wrapperClassName} flex flex-center flex-fit stretch ${
        additionalText && 'flex-align-items-center flex-gap-4'
      }`}
      style={
        {
          '--loader-height': iconHeight ? iconHeight + 'px' : '32px',
          '--loader-width': iconWidth ? iconWidth + 'px' : '32px',
        } as React.CSSProperties
      }
    >
      <Icon icon={LoaderIcon} className="rotate app-loader" />
      {additionalText}
    </div>
  );
};

export default Loader;
