//Remix icons
export enum ICONS {
  SEARCH_2_LINE = 'ri-search-2-line',
  ADD_LINE = 'ri-add-line',
  SUBTRACT_LINE = 'ri-subtract-line',
}

//Remix icon sizes
export enum ICONS_SIZE {
  // fixed width
  FW = 'ri-fw',
  // 0.5em
  XXS = 'ri-xxs',
  // 0.75em
  XS = 'ri-xs',
  // 0.875em
  SM = 'ri-sm',
  // 1em
  ONE_X = 'ri-1x',
  // 1.3333em
  LG = 'ri-lg',
  // 1.5em
  XL = 'ri-xl',
  // 2em
  TWO_X = 'ri-2x',
  // 3em
  THREE_X = 'ri-3x',
  // 4em
  FOUR_X = 'ri-4x',
  // 5em
  FIVE_X = 'ri-5x',
  // 6em
  SIX_X = 'ri-6x',
  // 7em
  SEVEN_X = 'ri-7x',
  // 8em
  EIGHT_X = 'ri-8x',
  // 9em
  NINE_X = 'ri-9x',
  // 10em
  TEN_X = 'ri-10x',
}
