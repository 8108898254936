import i18n from 'i18n';

/**
 * @function isEmptyField
 * @description Function to validate if the string value is empty
 * @param value string value to be validated
 * @returns boolean true if empty, else false
 */
export const isEmptyField = (value: string | number | undefined) => {
  if (!value) {
    return true;
  }

  if (typeof value === 'number') return !value;

  return !value.trim();
};

/**
 * @function validateEmail
 * @description Function to validate email address
 * @param email string email to be validated
 * @returns boolean true if valid email, else false
 */
export const validateEmail = (email: string) => {
  if (
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/.test(
      email
    )
  ) {
    return true;
  }
  return false;
};

/**
 * @function validateEmptyField
 * @description Function to validate empty field and set error message
 * @param value string value to be validated
 * @param inputFieldName string field name to be validated
 * @param setValidation callback for setting validation message
 * @param setErrorMessage A boolean indicating whether to set the error message or not true by default
 * @returns true if empty field, false otherwise
 */
export const validateEmptyField = (
  value: string | number | undefined,
  inputFieldName: string,
  setValidation: (val: string) => void,
  setErrorMessage: boolean = true
) => {
  if (isEmptyField(value)) {
    setErrorMessage &&
      setValidation(`${inputFieldName} ${i18n.t('isRequired')}`);
    return true;
  }

  setErrorMessage && setValidation('');
  return false;
};

/**
 * @function getValidationStyle
 * @description Function to return the validation message display styling
 * @param validation string validation message
 * @return string display style
 */
export const getValidationStyle = (validation: string) =>
  validation ? 'inline' : 'none';
