import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from 'redux/store';
import App from './App';

import 'antd/dist/reset.css';
import 'remixicon/fonts/remixicon.css';
import './styles/global.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLDivElement
);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
