import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormLabel } from 'components/FormLabel';
import Input from 'components/Input';
import Button from 'components/Button';
import {
  getValidationStyle,
  validateEmail,
  validateEmptyField,
} from 'utils/validations';
import { onApiCallError } from 'utils/handleErrors';
import { REQUEST_STATUS } from 'constants/requestBody';

import { validateLoginCredentials } from '../../services';

type LoginCredentialsProps = {
  emailAddress: string;
  setEmailAddress: (val: string) => void;
  password: string;
  setPassword: (val: string) => void;
  setShowValidateMFA: (val: boolean) => void;
};

const LoginCredentialsForm = ({
  emailAddress,
  setEmailAddress,
  password,
  setPassword,
  setShowValidateMFA,
}: LoginCredentialsProps) => {
  const { t } = useTranslation();

  const [emailAddressValidation, setEmailAddressValidation] = useState('');

  const [passwordValidation, setPasswordValidation] = useState('');
  const [validateCredentialsReqStatus, setValidateCredentialsReqStatus] =
    useState(REQUEST_STATUS.SUCCESS);
  const [validationMessage, setValidationMessage] = useState('');

  /**
   * @function validateCredentials
   * @description Function to validate the login credentials
   */
  const validateCredentials = () => {
    setValidateCredentialsReqStatus(REQUEST_STATUS.PROCESSING);

    const requestBody = {
      userEmail: emailAddress,
      password: password,
      needMFAMailAgain: false,
    };

    validateLoginCredentials(requestBody)
      .then((res: any) => {
        if (res?.status === 204) {
          setValidateCredentialsReqStatus(REQUEST_STATUS.SUCCESS);
          setShowValidateMFA(true);
          return;
        }
        setValidateCredentialsReqStatus(REQUEST_STATUS.ERROR);
        setValidationMessage(t('loginLabels.invalidCredentials'));
      })
      .catch((e) => {
        onApiCallError(e, false, setValidateCredentialsReqStatus);
        setValidationMessage(t('loginLabels.invalidCredentials'));
      });
  };

  /**
   * @function validateEmailAddress
   * @description Function to validate the email address
   * @returns true if validation is successful else false
   */
  const validateEmailAddress = () => {
    if (
      validateEmptyField(
        emailAddress,
        t('loginLabels.emailAddress'),
        setEmailAddressValidation
      )
    ) {
      return false;
    }

    if (!validateEmail(emailAddress)) {
      setEmailAddressValidation(t('loginLabels.enterValidEmail'));
      return false;
    }

    setEmailAddressValidation('');
    return true;
  };

  /**
   * @function validatePassword
   * @description Function to validate the password
   * @returns true if validation is successful else false
   */
  const validatePassword = () => {
    return !validateEmptyField(
      password,
      t('loginLabels.password'),
      setPasswordValidation
    );
  };

  /**
   * @function validateLoginFields
   * @description Function to validate the login fields
   * @returns true if validation is successful else false
   */
  const validateLoginFields = () => {
    let validation = true;
    if (!validateEmailAddress()) {
      validation = false;
    }

    validation = validatePassword() && validation;

    return validation;
  };

  /**
   * @function onClickLogin
   * @description Callback function for onclick login button
   */
  const onClickLogin = () => {
    if (!validateLoginFields()) {
      return;
    }

    validateCredentials();
  };

  return (
    <>
      <div className="title-section flex flex-column flex-gap-4">
        <div className="font-extra-large-bolder">
          {t('loginLabels.loginToAccount')}
        </div>

        <div className="font-subHeader sub-heading">
          {t('loginLabels.loginWelcomeMessage')}
        </div>
      </div>
      <div className="flex flex-column flex-gap-16">
        <div>
          <FormLabel title={t('loginLabels.emailAddress')} />
          <Input
            value={emailAddress}
            onChange={(e: any) => {
              setEmailAddressValidation('');
              setValidationMessage('');
              setEmailAddress(e.target.value);
            }}
            onBlur={validateEmailAddress}
            onPressEnter={() => onClickLogin()}
            disabled={
              validateCredentialsReqStatus === REQUEST_STATUS.PROCESSING
            }
            name="email-address"
          />
          <span
            style={{
              display: `${getValidationStyle(emailAddressValidation)}`,
            }}
            className="font-validation-error"
          >
            {emailAddressValidation}
          </span>
        </div>
        <div>
          <FormLabel title={t('loginLabels.password')} />
          <Input
            type="password"
            value={password}
            onChange={(e: any) => {
              setPasswordValidation('');
              setValidationMessage('');
              setPassword(e.target.value);
            }}
            onBlur={validatePassword}
            onPressEnter={() => onClickLogin()}
            disabled={
              validateCredentialsReqStatus === REQUEST_STATUS.PROCESSING
            }
          />
          <span
            style={{
              display: `${getValidationStyle(passwordValidation)}`,
            }}
            className="font-validation-error"
          >
            {passwordValidation}
          </span>
        </div>
        <Button
          title={t('loginBtn')}
          onClick={onClickLogin}
          loading={validateCredentialsReqStatus === REQUEST_STATUS.PROCESSING}
        />
        <span
          style={{
            display: `${getValidationStyle(validationMessage)}`,
          }}
          className="font-validation-error"
        >
          {validationMessage}
        </span>
      </div>
    </>
  );
};

export default LoginCredentialsForm;
