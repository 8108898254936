import { Navigate, useLocation } from 'react-router-dom';

import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import LoginRoute from 'pages/LoginPage';

const AuthorizedRoute = () => {
  const location = useLocation().pathname;
  return location ? (
    <LoginRoute />
  ) : (
    <Navigate to={NAVIGATION_MENU_PATH.NOT_AUTHORIZED} />
  );
};

export default AuthorizedRoute;
