import { useEffect, useState } from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import jwt_decode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { loginRequest } from 'authConfig';
import AppRouter from 'AppRouter';
import Loader from 'components/Loader';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import {
  setUserAuthorization,
  userAuthorization,
  setIsServerError,
} from 'redux/authorizationSlice';
import LoginWithSSO from 'pages/LoginPage/components/LoginWithSSO';
import { REQUEST_STATUS } from 'constants/requestBody';
import { evaluateRequestArray } from 'utils/handleErrors';
import { fetchRedirectUrl } from 'utils/services';
import { Config } from 'config';
import { RedirectionType } from 'pages/LoginPage/constants';

type SSOLoginRouteProps = {
  sessionExpired: boolean;
  setSessionExpired: (val: boolean) => void;
};

function SSOLoginRoute({
  sessionExpired,
  setSessionExpired,
}: SSOLoginRouteProps) {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isUserAuthorized } = useSelector(userAuthorization);

  const [azureADAccessToken, setAzureADAccessToken] = useState<string>('');
  const [azureAdIdToken, setAzureAdIdToken] = useState<string>('');
  const [clientUrl, setClientUrl] = useState('');
  const [fetchSsoAccessToken, setFetchSsoAccessToken] = useState(
    REQUEST_STATUS.PROCESSING
  );
  const [fetchAccessToken, setFetchAccessToken] = useState(
    REQUEST_STATUS.PROCESSING
  );

  useEffect(() => {
    requestAccessToken();
  }, []);

  useEffect(() => {
    if (isAuthenticated && azureADAccessToken && azureAdIdToken) {
      setFetchAccessToken(REQUEST_STATUS.PROCESSING);
      sessionStorage.setItem('access-token', azureADAccessToken);
      if (Config.redirection_type === RedirectionType.NGNIX) {
        dispatch(setUserAuthorization(true));
        dispatch(setIsServerError(false));
        setSessionExpired(false);
        const tokenData: any = jwt_decode(azureAdIdToken);
        setClientUrl(`http://34.36.205.246/?email=${tokenData.email}`);
        setFetchAccessToken(REQUEST_STATUS.SUCCESS);
        return;
      }

      fetchAndRedirectByUrl();
    }
  }, [azureADAccessToken, azureAdIdToken, isAuthenticated]);

  /**
   * @function requestAccessToken
   * @description Function to silently fetch access token or redirect users to login and get access token through sso
   */
  const requestAccessToken = () => {
    setFetchSsoAccessToken(REQUEST_STATUS.PROCESSING);
    const request = {
      ...loginRequest,
      account: instance.getActiveAccount() ?? undefined,
    };

    instance
      .ssoSilent(request)
      .then((response) => {
        setAzureADAccessToken(response.accessToken);
        setAzureAdIdToken(response.idToken);
        setFetchSsoAccessToken(REQUEST_STATUS.SUCCESS);
      })
      .catch((e) => {
        setFetchSsoAccessToken(REQUEST_STATUS.ERROR);
        if (
          [
            'login_required',
            'interaction_required',
            'consent_required',
            'no_account_error',
          ].includes(e?.errorCode)
        ) {
          instance.acquireTokenPopup(request).then((response) => {
            setAzureADAccessToken(response.accessToken);
            setAzureAdIdToken(response.idToken);
          });
        }
      });
  };

  /**
   * @function fetchAndRedirectByUrl
   * @description Function to fetch the redirect url
   */
  const fetchAndRedirectByUrl = () => {
    fetchRedirectUrl(azureADAccessToken)
      .then((res: any) => {
        let redirectUrl = res?.headers?.['location'];
        dispatch(setUserAuthorization(true));
        dispatch(setIsServerError(false));
        setSessionExpired(false);
        if (redirectUrl) {
          setClientUrl(redirectUrl);
        } else {
          dispatch(setUserAuthorization(false));
          return;
        }
        if (
          [
            NAVIGATION_MENU_PATH.NOT_AUTHORIZED.valueOf(),
            NAVIGATION_MENU_PATH.ERROR.valueOf(),
          ].includes(location.pathname)
        ) {
          navigate(NAVIGATION_MENU_PATH.DEFAULT);
        }
      })
      .catch((error) => {
        dispatch(setUserAuthorization(false));
        dispatch(setIsServerError(false));
        console.log('Error in accessing token -->', error);
        if (
          [
            NAVIGATION_MENU_PATH.NOT_AUTHORIZED.valueOf(),
            NAVIGATION_MENU_PATH.ERROR.valueOf(),
          ].includes(location.pathname)
        ) {
          navigate(NAVIGATION_MENU_PATH.DEFAULT);
        }
      })
      .finally(() => {
        setFetchAccessToken(REQUEST_STATUS.SUCCESS);
      });
  };

  const isLoading = () => {
    return (
      evaluateRequestArray([fetchAccessToken]) === REQUEST_STATUS.PROCESSING
    );
  };

  if (sessionExpired && isUserAuthorized) {
    return (
      <LoginWithSSO
        sessionExpired={sessionExpired}
        onClickLogin={requestAccessToken}
      />
    );
  }

  return (
    <div className="full-height">
      <AuthenticatedTemplate>
        {isLoading() ? <Loader /> : <AppRouter clientUrl={clientUrl} />}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        {fetchSsoAccessToken === REQUEST_STATUS.PROCESSING ? (
          <Loader />
        ) : (
          <LoginWithSSO
            sessionExpired={sessionExpired}
            onClickLogin={requestAccessToken}
          />
        )}
      </UnauthenticatedTemplate>
    </div>
  );
}

export default SSOLoginRoute;
