import { APIS } from 'constants/endpoints';
import { setHeaders, ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function fetchRedirectUrl
 * @description Function to fetch the redirect url
 * @param accessToken - AD Bearer token
 * @return axios response from GET request
 */
export const fetchRedirectUrl = async (accessToken: string) => {
  const headers = setHeaders(accessToken);
  return ServiceCalls.get(APIS.FETCH_REDIRECT_URL, headers);
};
