import { createElement } from 'react';
import { ICONS_SIZE } from 'constants/icons';
import { getIconTextColorByBackground } from 'utils/appearanceUtils';
import { useSelector } from 'react-redux';
import { selectTheme } from 'redux/themeSlice';

type IconProps = {
  iconName?: string;
  icon?: any;
  className?: string;
  id?: string;
  size?: string;
  color?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  isOnBackground?: boolean;
  dataTestId?: string;
};

const Icon = ({
  iconName,
  icon,
  className,
  id,
  size = ICONS_SIZE.LG,
  color,
  onClick,
  isOnBackground = false,
  dataTestId,
}: IconProps) => {
  const { theme } = useSelector(selectTheme);

  const getColor = () => {
    return isOnBackground
      ? getIconTextColorByBackground(theme.buttonIconColor)
      : color;
  };

  if (icon) {
    return createElement(icon, {
      color: getColor(),
      className: `${className} cursor-pointer`,
      onClick: onClick,
      id: id,
    });
  }

  return (
    <i
      className={`${iconName} ${size} ${className} cursor-pointer`}
      style={{ color: getColor() }}
      onClick={onClick}
      id={id}
      data-testid={dataTestId}
    />
  );
};

export default Icon;
