import { useTranslation } from 'react-i18next';
import { DeloitteLogo, Logo } from 'assets/icons';
import Button from 'components/Button';

import './index.scss';

type LoginPageProps = {
  sessionExpired: boolean;
  onClickLogin: Function;
};

const LoginPage = ({ sessionExpired, onClickLogin }: LoginPageProps) => {
  const { t } = useTranslation();

  return (
    <div className="login-page flex flex-column full-height">
      <div className="login-header-section flex flex-row flex-align-items-center flex-space-between">
        <Logo className="app-logo white-app-logo" />
        <DeloitteLogo className="deloitte-logo" />
      </div>
      <div className="body-section flex flex-center stretch">
        <div className="section-container flex flex-center flex-column">
          <div className="content-section full-width flex flex-column flex-justify-content-center">
            <div className="title-section">
              {sessionExpired ? t('sessionExpired') : t('loginPageTitle')}
            </div>
            <Button
              title={sessionExpired ? t('refreshSession') : t('loginBtn')}
              onClick={onClickLogin}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
