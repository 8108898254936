import { INPUT_SIZE } from 'constants/appearance';

/**
 * @function changeAlphaOfColor
 * @description Change the alpha of a given color
 * @param color hex code of the color
 * @param alpha percentage of the alpha between 0 and 100
 * @returns hex code of color with the new alpha
 */
export const changeAlphaOfColor = (color: string, alpha: number) => {
  const colorWithoutAlpha = color.slice(0, 7);
  let alphaHex = Math.round((alpha * 255) / 100).toString(16);
  if (alphaHex.length < 2) alphaHex = '0' + alpha;
  return colorWithoutAlpha + alphaHex;
};

/**
 * @function getIconTextColorByBackground
 * @description Function to get the icon color based on the background lightness
 * @param hex hex code of the background color
 * @returns color to be used for the icon or text
 */
export function getIconTextColorByBackground(hex: string) {
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);
  // Calculate lightness based on the formula from https://www.w3.org/TR/WCAG20/#relativeluminancedef
  const lightness = (r * 299 + g * 587 + b * 114) / 1000;
  const lightnessPercentage = (lightness / 255) * 100;
  // Return white if color is too dark and vice versa
  return lightnessPercentage > 65 ? '#000' : '#fff';
}

/**
 * @function getClassnameBySize
 * @description Function to return the classname based on the size for input components
 * @param size string size of the input
 * @returns string classname
 */
export const getClassnameBySize = (size: string) => {
  if (size === INPUT_SIZE.SMALL) return 'input-small';

  return 'input-medium';
};

/**
 * @function convertHexToHsl
 * @description Convert a hex color to HSL
 * @param hexColor hex code of the color to be converted
 * @param replaceWith An object containing the HSL values to be replaced with the converted hsl values
 * @returns Converted HSL color
 */
export const convertHexToHsl = (
  hexColor: string,
  replaceWith?: { h?: number; s?: number; l?: number }
) => {
  // Convert hex to RGB first
  let r: any, g: any, b: any;
  const result =
    /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor) ?? [];
  r = parseInt(result[1], 16);
  g = parseInt(result[2], 16);
  b = parseInt(result[3], 16);

  r /= 255;
  g /= 255;
  b /= 255;
  let max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h = (max + min) / 2;
  let s;
  let l = (max + min) / 2;
  if (max === min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  h = Math.round(h * 360);
  s = Math.round(s * 100);
  l = Math.round(l * 100);

  if (replaceWith) {
    return replaceHslValues(h, s, l, replaceWith);
  }

  return 'hsl(' + h + ',' + s + '%,' + l + '%)';
};

/**
 * @function replaceHslValues
 * @description Replace with the converted hsl values of a given HSL color
 * @param h Hue value of the HSL color
 * @param s Saturation value of the HSL color
 * @param l Lightness value of the HSL color
 * @param replaceWith An object containing the HSL values to be replaced with the converted hsl values
 * @returns Converted HSL color
 */
const replaceHslValues = (
  h: number,
  s: number,
  l: number,
  replaceWith: { h?: number; s?: number; l?: number }
) => {
  const newH = replaceWith.h ? replaceWith.h : h;
  const newS = replaceWith.s ? replaceWith.s : s;
  const newL = replaceWith.l ? replaceWith.l : l;

  return 'hsl(' + newH + ',' + newS + '%,' + newL + '%)';
};
