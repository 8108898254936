import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-center flex-column">
      <h1>{t('notFound')}</h1>
    </div>
  );
};

export default NotFoundPage;
