import { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setIsServerError } from 'redux/authorizationSlice';
import { selectTheme } from 'redux/themeSlice';
import SSOLoginRoute from 'components/SSOLoginRoute';
import MFALoginRoute from 'pages/LoginPage/components/LoginWithCredentials';
import { Config } from 'config';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import { convertHexToHsl } from 'utils/appearanceUtils';

import { LoginTypes } from './constants';

const LoginRoute = () => {
  const loginType = Config.login_type;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { theme } = useSelector(selectTheme);

  const [sessionExpired, setSessionExpired] = useState(false);

  useEffect(() => {
    const UNAUTHORIZED = 401;
    const SERVER_ERROR = 503;

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (axios.isCancel(error)) {
          return Promise.reject(error);
        }

        const status = error?.response?.status;

        if (status === UNAUTHORIZED && loginType === LoginTypes.MFA) {
          localStorage.clear();
          navigate(NAVIGATION_MENU_PATH.LOGIN);
          return;
        }

        if (status === UNAUTHORIZED) {
          setSessionExpired(true);
        }

        if (status === SERVER_ERROR) {
          dispatch(setIsServerError(true));
        }

        return Promise.reject(error);
      }
    );
  }, []);

  return (
    <div
      className="stretch"
      style={
        {
          color: theme.textColor,
          '--text-color': theme.textColor,
          '--primary-color': theme.primaryColor,
          '--button-icon-color': theme.buttonIconColor,
          '--table-header-color': convertHexToHsl(theme.primaryColor, {
            l: 96,
          }),
        } as React.CSSProperties
      }
    >
      {loginType === LoginTypes.MFA ? (
        <MFALoginRoute sessionExpired={sessionExpired} />
      ) : (
        <SSOLoginRoute
          sessionExpired={sessionExpired}
          setSessionExpired={setSessionExpired}
        />
      )}
    </div>
  );
};

export default LoginRoute;
