import { APIS } from 'constants/endpoints';
import { ServiceCalls } from 'utils/ServiceCalls';

/**
 * @function validateLoginCredentials
 * @description Function to validate the login credentials
 * @param body body for post request.
 * @return axios response from POST request
 */
export const validateLoginCredentials = async (body: any) => {
  return ServiceCalls.post(APIS.VERIFY_LOGIN_CREDENTIALS, body);
};

/**
 * @function verifyAthenticatorOtp
 * @description Function to verify the authenticator otp
 * @param params request params
 * @return axios response from GET request
 */
export const verifyAthenticatorOtp = async (params: any) => {
  return ServiceCalls.get(APIS.VERIFY_OTP, undefined, params);
};
