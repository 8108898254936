import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { ConfigProvider } from 'antd';
import { useSelector } from 'react-redux';
import { Routes, BrowserRouter, Route } from 'react-router-dom';

import { msalConfig } from 'authConfig';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import LoginRoute from 'pages/LoginPage';
import HealthReadinessPage from 'pages/HealthReadinessPage';
import { selectTheme } from 'redux/themeSlice';

import './i18n';

function App() {
  const { theme } = useSelector(selectTheme);
  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: theme.primaryColor,
        },
      }}
    >
      <MsalProvider instance={msalInstance}>
        <BrowserRouter>
          <Routes>
            <Route
              path={NAVIGATION_MENU_PATH.HEALTH_READINESS}
              element={<HealthReadinessPage />}
            />
            <Route path="*" element={<LoginRoute />} />
          </Routes>
        </BrowserRouter>
      </MsalProvider>
    </ConfigProvider>
  );
}

export default App;
