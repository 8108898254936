import { message } from 'antd';
import i18n from 'i18n';
import axios from 'axios';

import { ERROR_KEY, REQUEST_STATUS } from 'constants/requestBody';

/**
 * @function onApiCallError
 * @description Function to handle error if API fails and show messages accordingly
 * @param e Error details that occurred.
 * @param setRequestStatus Function to set the requestStatus for API to error.
 * @param errorMessage Optional string error message
 */
export const onApiCallError = (
  e: any,
  includeToastMessage: boolean = true,
  setRequestStatus?: (val: string) => void,
  errorMessage?: string
) => {
  if (axios.isCancel(e)) {
    return;
  }

  console.log(e);
  setRequestStatus?.(REQUEST_STATUS.ERROR);
  if (includeToastMessage) {
    message.error({
      content: errorMessage ?? i18n.t('apiCallErrorMessage'),
      key: ERROR_KEY,
    });
  }
};

/**
 * @function evaluateRequestArray
 * @description Function which evaluates an array of request status into a single status.
 * @param requests The array of request statuses
 * @returns processing/error if atleast one of them is found processing/error.
 * Or returns success if all of them are success.
 */
export const evaluateRequestArray = (requests: string[]) => {
  if (requests.every((value) => value === REQUEST_STATUS.SUCCESS))
    return REQUEST_STATUS.SUCCESS;
  if (requests.includes(REQUEST_STATUS.ERROR)) return REQUEST_STATUS.ERROR;
  else return REQUEST_STATUS.PROCESSING;
};
