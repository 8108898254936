import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_THEME } from 'constants/appearance';
import { ColorPalleteType } from 'types/appearance';
import { RootState } from './store';

export interface ThemeState {
  theme: ColorPalleteType;
}

const initialState: ThemeState = {
  theme: DEFAULT_THEME,
};

export const themeSlice = createSlice({
  name: 'themeSlice',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<ColorPalleteType>) => {
      state.theme = action.payload;
    },
  },
});

export const selectTheme = (state: RootState) => state.theme;
export const { setTheme } = themeSlice.actions;
export default themeSlice.reducer;
