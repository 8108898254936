import { useTranslation } from 'react-i18next';

const ServerErrorPage = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-center flex-column">
      <h1>{t('serverErrorMessage')}</h1>
    </div>
  );
};

export default ServerErrorPage;
