export enum Instances {
  LOCALHOST = 'localhost',
  DEV = 'devportal-cb360.us.deloitte.com',
  QA = 'qaportal-cb360.us.deloitte.com',
  PRUDENTIAL = 'prudentialportal-cb360.us.deloitte.com',
  GLOBAL_QA = 'globalqaportal-cb360.us.deloitte.com',
}

export enum APIS {
  FETCH_REDIRECT_URL = 'auth/redirecting',
  VERIFY_OTP = 'authz/auth/verifyOTP',
  VERIFY_LOGIN_CREDENTIALS = 'authz/auth/verifyCredentials',
}
