/**
 * @function incrementNumberBySteps
 * @description Function to increment the number by steps or 1 by default
 */
export const incrementNumberBySteps = (
  value: any,
  max: any,
  steps: any,
  onChange: Function
) => {
  if (isNaN(Number(value))) {
    onChange(1);
    return;
  }

  if (max && Number(value) >= max) {
    return;
  }

  onChange(Number(value) + steps);
};

/**
 * @function decrementNumberBySteps
 * @description Function to decrement the number by steps or 1 by default
 */
export const decrementNumberBySteps = (
  value: any,
  min: any,
  steps: any,
  onChange: Function
) => {
  if (isNaN(Number(value))) {
    onChange(1);
    return;
  }

  if (min && Number(value) <= min) {
    return;
  }

  onChange(Number(value) - (steps ?? 1));
};

/**
 * @function onChangeStepNumber
 * @description Callback function for step number input on change event
 * @param e event input
 */
export const onChangeStepNumber = (
  e: React.ChangeEvent<HTMLInputElement>,
  onChange: Function
) => {
  if (!e.target.value) {
    onChange(0);
    return;
  }

  onChange(e.target.value);
};
