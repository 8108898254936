import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface AuthorizationState {
  isUserAuthorized: boolean;
  isServerError: boolean;
}

const initialState: AuthorizationState = {
  isUserAuthorized: false,
  isServerError: false,
};

export const authorizationSlice = createSlice({
  name: 'authorizationSlice',
  initialState,
  reducers: {
    setUserAuthorization: (state, action: PayloadAction<boolean>) => {
      state.isUserAuthorized = action.payload;
    },
    setIsServerError: (state, action: PayloadAction<boolean>) => {
      state.isServerError = action.payload;
    },
  },
});

export const userAuthorization = (state: RootState) => state.userAuthorization;
export const { setUserAuthorization, setIsServerError } =
  authorizationSlice.actions;
export default authorizationSlice.reducer;
