import { useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  setIsServerError,
  setUserAuthorization,
} from 'redux/authorizationSlice';
import AppRouter from 'AppRouter';
import Loader from 'components/Loader';
import { DeloitteLogo, Logo } from 'assets/icons';
import { REQUEST_STATUS } from 'constants/requestBody';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';

import LoginCredentialsForm from './components/LoginCredentialsForm';
import ValidateMFA from './components/ValidateMFA';

import './index.scss';

type MFALoginRouteProps = {
  sessionExpired: boolean;
};

const MFALoginRoute = ({ sessionExpired }: MFALoginRouteProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [requireLogin, setRequireLogin] = useState(true);
  const [showValidateMFA, setShowValidateMFA] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [fetchAccessTokenReqStatus, setFetchAccessTokenReqStatus] = useState(
    REQUEST_STATUS.PROCESSING
  );

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const expiresAt = localStorage.getItem('expiresAt');
    if (
      !accessToken ||
      !expiresAt ||
      Number(expiresAt) < new Date().getTime() / 1000
    ) {
      setFetchAccessTokenReqStatus(REQUEST_STATUS.SUCCESS);
      setRequireLogin(true);
      return;
    }

    setUserPermissions(accessToken);
  }, []);

  const setUserPermissions = (accessToken: string) => {
    const tokenData: any = jwt_decode(accessToken);
    dispatch(setUserAuthorization(true));
    dispatch(setIsServerError(false));
    dispatch(setUserAuthorization(true));
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('name', tokenData.name);
    localStorage.setItem('email', tokenData.sub);
    localStorage.setItem('role', tokenData.role);
    localStorage.setItem('expiresAt', tokenData.exp);
    localStorage.setItem('issuedAt', tokenData.iat);

    if (
      [
        NAVIGATION_MENU_PATH.NOT_AUTHORIZED.valueOf(),
        NAVIGATION_MENU_PATH.ERROR.valueOf(),
      ].includes(location.pathname)
    ) {
      navigate(NAVIGATION_MENU_PATH.DEFAULT);
    }
    setRequireLogin(false);
    setFetchAccessTokenReqStatus(REQUEST_STATUS.SUCCESS);
  };

  if (fetchAccessTokenReqStatus === REQUEST_STATUS.PROCESSING) {
    return <Loader />;
  }

  return requireLogin ? (
    <div className="credentials-login-page flex flex-column full-height">
      <div className="login-header-section flex flex-row flex-align-items-center flex-space-between">
        <Logo className="app-logo white-app-logo" />
        <div />
        <DeloitteLogo className="deloitte-logo" />
      </div>
      <div className="body-section flex flex-center stretch">
        <div className="section-container flex flex-center flex-column">
          <div className="content-section full-width flex flex-column flex-justify-content-center">
            {showValidateMFA ? (
              <ValidateMFA
                emailAddress={emailAddress}
                password={password}
                setPassword={setPassword}
                setShowValidateMFA={setShowValidateMFA}
                setUserPermissions={setUserPermissions}
              />
            ) : (
              <LoginCredentialsForm
                emailAddress={emailAddress}
                setEmailAddress={setEmailAddress}
                password={password}
                setPassword={setPassword}
                setShowValidateMFA={setShowValidateMFA}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <AppRouter />
  );
};

export default MFALoginRoute;
