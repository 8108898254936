import { Configuration } from '@azure/msal-browser';
import { Config } from 'config';

export const msalConfig: Configuration = {
  auth: {
    clientId: Config.azure_client_id,
    authority: `https://login.microsoftonline.com/${Config.azure_tenant_id}`,
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.read'],
};
