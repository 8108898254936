import axios from 'axios';
import { Config } from 'config';
import qs from 'qs';

const AZURE_AD_SERVICE_URL = Config.azure_ad_url;
let SERVICE_URL = Config.be_service_url;

export class ServiceCalls {
  static get(api: string, headers?: any, params?: any, cancelToken?: any) {
    const URL = SERVICE_URL + api;
    return new Promise((resolve, reject) => {
      axios
        .get(URL, {
          headers: headers?.headers,
          params,
          cancelToken,
          paramsSerializer: (_reqParams) =>
            qs.stringify(params, { arrayFormat: 'repeat' }),
          withCredentials: true,
        })
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }

  static post(
    api: string,
    body?: any,
    headers?: any,
    params?: any,
    cancelToken?: any
  ) {
    const URL = SERVICE_URL + api;
    return new Promise((resolve, reject) => {
      axios
        .post(URL, body, {
          headers: headers?.headers,
          params,
          cancelToken,
          withCredentials: true,
        })
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }

  static put(
    url: string,
    body: any,
    headers: any,
    completeUrl?: boolean,
    params?: any
  ) {
    const URL = completeUrl ? url : SERVICE_URL + url;
    return new Promise((resolve, reject) => {
      axios
        .put(URL, body, {
          headers: headers.headers,
          params,
          withCredentials: true,
        })
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }

  static delete(api: string, headers: any, params?: any) {
    const URL = SERVICE_URL + api;
    return new Promise((resolve, reject) => {
      axios
        .delete(URL, {
          headers: headers.headers,
          params,
          withCredentials: true,
        })
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }

  static getFromAzure(api: string, headers: any) {
    const URL = AZURE_AD_SERVICE_URL + api;
    return new Promise((resolve, reject) => {
      axios
        .get(URL, headers)
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }
}

export const setHeaders = (accessToken: string | null) => {
  const headers = {
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Origin': '*',
    Authorization: 'Bearer ' + accessToken,
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
  };
  return {
    headers: headers,
  };
};
