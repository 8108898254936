import { useTranslation } from 'react-i18next';

const UnAuthorizedPage = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-center flex-column">
      <h1>{t('notAuthorizedMessage')}</h1>
    </div>
  );
};

export default UnAuthorizedPage;
