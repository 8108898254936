import { useTranslation } from 'react-i18next';

const HealthReadinessPage = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-center flex-column">
      <h1>{t('healthyMessage')}</h1>
    </div>
  );
};

export default HealthReadinessPage;
