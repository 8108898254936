import './index.scss';

type FormLabelProps = {
  title: string;
  required?: boolean;
  disabled?: boolean;
  subtitle?: React.ReactNode;
};

export const FormLabel = ({
  title,
  required,
  disabled,
  subtitle,
}: FormLabelProps) => {
  return (
    <div className="form-label">
      <div
        className={`font-caption-bold ${required ? 'required-asterisk' : ''} ${
          disabled ? 'disabled' : ''
        }`}
      >
        {title}
      </div>
      <div className="font-caption">{subtitle}</div>
    </div>
  );
};
