import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import UnAuthorizedPage from 'pages/UnauthorisedPage';
import NotFoundPage from 'pages/NotFoundPage';
import ServerErrorPage from 'pages/ServerErrorPage';
import LoginRoute from 'pages/LoginPage';
import { userAuthorization } from 'redux/authorizationSlice';
import { NAVIGATION_MENU_PATH } from 'constants/navigationMenu';
import PrivateRoute from './PrivateRoute';
import AuthorizedRoute from './AuthorizedRoute';

type AppRouterProps = {
  clientUrl?: string;
};

const AppRouter = ({ clientUrl }: AppRouterProps) => {
  const { isUserAuthorized } = useSelector(userAuthorization);

  if (clientUrl) {
    window.location.assign(clientUrl);
  }

  return (
    <Routes>
      <Route
        element={
          <PrivateRoute auth={isUserAuthorized}>
            <AuthorizedRoute />
          </PrivateRoute>
        }
      >
        <Route path={NAVIGATION_MENU_PATH.DEFAULT} element={<LoginRoute />} />
      </Route>
      <Route path="/login" element={<LoginRoute />} />
      <Route
        path={NAVIGATION_MENU_PATH.NOT_AUTHORIZED}
        element={<UnAuthorizedPage />}
      />
      <Route path={NAVIGATION_MENU_PATH.ERROR} element={<ServerErrorPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRouter;
