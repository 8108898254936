export enum BUTTON_SIZE {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum BUTTON_TYPE {
  PRIMARY = 'primary',
  NEUTRAL = 'neutral',
  OUTLINED = 'outlined',
  LINK = 'link',
}

export enum INPUT_SIZE {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
}

export const INPUT_PADDING = 4;

export const DEFAULT_THEME = {
  templateName: 'Deloitte',
  primaryColor: '#86BC25',
  secondaryColor: '#8d686d',
  buttonIconColor: '#4E8934',
  backgroundColor: '#f5f6f5',
  textColor: '#0A101A',
};
