import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';

import Button from 'components/Button';
import Input from 'components/Input';
import { BUTTON_TYPE } from 'constants/appearance';
import { ERROR_KEY, REQUEST_STATUS, SUCCESS_KEY } from 'constants/requestBody';
import { onApiCallError } from 'utils/handleErrors';
import { getValidationStyle } from 'utils/validations';

import {
  validateLoginCredentials,
  verifyAthenticatorOtp,
} from '../../services';

type ValidateMFAProps = {
  setShowValidateMFA: (val: boolean) => void;
  emailAddress: string;
  password: string;
  setPassword: (val: string) => void;
  setUserPermissions: (accessToken: string) => void;
};

const ValidateMFA = ({
  setShowValidateMFA,
  emailAddress,
  password,
  setPassword,
  setUserPermissions,
}: ValidateMFAProps) => {
  const { t } = useTranslation();

  const [otp, setOtp] = useState<string[]>(Array(6).fill(''));
  const [validationMessage, setValidationMessage] = useState('');
  const [verifyOtpReqStatus, setVerifyOtpReqStatus] = useState(
    REQUEST_STATUS.SUCCESS
  );

  useEffect(() => {
    focusOtpField();

    return () => {
      setPassword('');
    };
  }, []);

  /**
   * @function sendQrCodeToEmail
   * @description Function to send the qr code to email
   */
  const sendQrCodeToEmail = () => {
    const requestBody = {
      userEmail: emailAddress,
      password: password,
      needMFAMailAgain: true,
    };

    validateLoginCredentials(requestBody)
      .then((res: any) => {
        if (res?.status === 204) {
          message.success({
            content: t('loginLabels.qrSentSuccessMessage'),
            key: SUCCESS_KEY,
          });
          setShowValidateMFA(true);
          return;
        }
        message.error({
          content: t('loginLabels.qrSentErrorMessage'),
          key: ERROR_KEY,
        });
      })
      .catch((e) => {
        onApiCallError(e, false);
        message.error({
          content: t('loginLabels.qrSentErrorMessage'),
          key: ERROR_KEY,
        });
      });
  };

  const focusOtpField = (index: number = 0) => {
    const nextfield = document.getElementsByName(`otp-field-${index}`);

    // If found, focus the field
    if (nextfield !== null) {
      nextfield[0].focus();
    }
  };
  const onClickDelete = (index: number) => {
    const currentOtp = [...otp];
    if (currentOtp.at(index)) {
      currentOtp[index] = '';
      setOtp(currentOtp);
      return;
    }

    const currentIndex = index === 0 ? 5 : index - 1;
    focusOtpField(currentIndex);
    currentOtp[currentIndex] = '';
    setOtp(currentOtp);
  };

  const onChangeOtpField = (e: any, index: number) => {
    if (e.key !== 'Enter') {
      setValidationMessage('');
    }

    if (e.keyCode === 8) {
      onClickDelete(index);
      return;
    }

    if (e.key >= '0' && e.key <= '9') {
      const currentOtp = [...otp];
      currentOtp[index] = e.key;
      setOtp(currentOtp);
      focusOtpField(index === 5 ? 0 : index + 1);
    }
  };

  const onClickVerify = () => {
    const otpString = otp.join('');
    if (otpString.length !== 6) {
      setValidationMessage(t('loginLabels.otpLimitValidationMessage'));
      focusOtpField(otp.findIndex((value) => !value));
      return;
    }

    setVerifyOtpReqStatus(REQUEST_STATUS.PROCESSING);
    setValidationMessage('');

    const params = {
      email: emailAddress,
      otp: otpString,
    };

    verifyAthenticatorOtp(params)
      .then((res: any) => {
        if (res?.status === 200) {
          setValidationMessage('');
          setUserPermissions(res?.data?.accessToken);
          setVerifyOtpReqStatus(REQUEST_STATUS.SUCCESS);
          return;
        }
        setValidationMessage(t('loginLabels.invalidCode'));
        setVerifyOtpReqStatus(REQUEST_STATUS.ERROR);
      })
      .catch((e) => {
        onApiCallError(e, false, setVerifyOtpReqStatus);
        setValidationMessage(t('loginLabels.invalidCode'));
      });
  };

  return (
    <div className="flex flex-column flex-gap-16">
      <div className="title-section flex flex-column flex-gap-4">
        <div className="font-extra-large-bolder">
          {t('loginLabels.verifyCode')}
        </div>
        <div className="font-subHeader sub-heading">
          {t('loginLabels.enterAuthCode')}
        </div>
      </div>
      <div className="flex flex-column flex-gap-16">
        <div className="flex flex-center flex-gap-16">
          {[...Array(6)]
            .map((_item, index) => index)
            .map((item: any, index: number) => (
              <Input
                rootClassName="otp-input-field"
                key={item}
                value={otp?.at(index)}
                onKeyDown={(e: any) => onChangeOtpField(e, index)}
                name={`otp-field-${index}`}
                autoComplete="off"
                onPressEnter={() => onClickVerify()}
              />
            ))}
        </div>
        <span
          style={{
            display: `${getValidationStyle(validationMessage)}`,
          }}
          className="font-validation-error"
        >
          {validationMessage}
        </span>
        <Button
          title={t('loginLabels.verify')}
          onClick={onClickVerify}
          loading={verifyOtpReqStatus === REQUEST_STATUS.PROCESSING}
        />
        <Button
          title={t('loginLabels.cancel')}
          type={BUTTON_TYPE.OUTLINED}
          onClick={() => setShowValidateMFA(false)}
        />
        <div className="qr-code flex flex-center flex-gap-4">
          <span className="query-message font-button">
            {t('loginLabels.dontHaveQrCode')}
          </span>
          <span>
            <Button
              className="send-again-cta"
              buttonTitleClassName="font-button"
              title={t('loginLabels.sendAgain')}
              type={BUTTON_TYPE.LINK}
              onClick={sendQrCodeToEmail}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default ValidateMFA;
